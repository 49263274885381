.App {
    font-family: "Tahoma", serif;
}

.App header {
    background: rgb(193,0,0,255);
    text-align: left;
    padding-left: 5em;
    border-bottom: 2px solid rgb(78, 78, 78);
    position: sticky;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 999;
    box-shadow: 0 2px 24px 0 rgb(0 0 0 / 15%);
    animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
    padding-top: 0.50em;
    padding-bottom: 0.50em;
}

.App header .buttons1 Button {
    color: white;
    background-color: #ffffff;
    text-shadow: -1px 0 #000, 0 1px #000, 1px 0 #000, 0 -1px #000;
    font-size: large;
}

.App header .buttons2 Button {
    color: #000;
    font-size: large;
    border-color: #000;
    margin: 0.35em 2em 0.35em 0.75em;
}

.App header .buttons3 Button{
    color: #000;
    float: right;
    font-size: large;
    border-color: #000;
    margin: 0.35em 2em 0.35em 0.35em;
}

.App .search {
    margin: 1em;
}

.App .home {
    margin: 1em;
}

.App .phi {
    margin: 1em;
}

.App .ButtonGroup{
    margin-top: 1em;
}

.App .linkcsv {
    float: right;
    margin: 0.2em;
}

.App .linkcsv img {
    width: 5em;
    height: 5em;
}

.App .buttons1 img {
    width: 2.5em;
    height: 2.5em;
}

.App .home img {
    width: 5em;
    height: 5em;
}

.linkcsv, .graph
{
    display:inline;
}
svg {
    width: 100%;
    height: 50em;
}
